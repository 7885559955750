#app {
    height: 100%;
    }
    html,
    body {
    position: relative;
    height: 100%;
    }
    
    :root {
    --swiper-navigation-size: 30px;
    }
    
    img {
    user-select: none;
    border-radius: 8px;
    cursor: pointer;
    }
    
    /* body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    } */
    
    .swiper {
    width: 100%;
    height: 100%;
    }
    
    .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    }